//行业
export const trade = [{
        name: "电子制造/电气/半导体",
        val: "电子制造/电气/半导体"
    },
    {
        name: "食品/饮料/保健品",
        val: "食品/饮料/保健品"
    },
    {
        name: "日化/美容",
        val: "日化/美容"
    },
    {
        name: "汽车、摩托车制造/配件",
        val: "汽车、摩托车制造/配件"
    },
    {
        name: "制药/生物科技",
        val: "制药/生物科技"
    },
    {
        name: "化工/能源/精细化工",
        val: "化工/能源/精细化工"
    },
    {
        name: "医疗设备/器械",
        val: "医疗设备/器械"
    },
    {
        name: "印刷/包装/造纸",
        val: "印刷/包装/造纸"
    },
    {
        name: "家具/家电/工艺品/玩具",
        val: "家具/家电/工艺品/玩具"
    },
    {
        name: "汽办公用品及设备",
        val: "汽办公用品及设备"
    },
    {
        name: "机械/设备/重工",
        val: "机械/设备/重工"
    },
    {
        name: "物流/运输",
        val: "物流/运输"
    },
    {
        name: "物业管理/房地产中介",
        val: "物业管理/房地产中介"
    },
    {
        name: "超市/百货/商场",
        val: "超市/百货/商场"
    },
    {
        name: "汽电商/外卖/配送",
        val: "汽电商/外卖/配送"
    },
    {
        name: "政府部门/事业单位",
        val: "政府部门/事业单位"
    },
    {
        name: "汽教育/培训",
        val: "汽教育/培训"
    },
    {
        name: "家政/清洁/园艺/环卫",
        val: "家政/清洁/园艺/环卫"
    },
    {
        name: "酒店/餐饮/娱乐",
        val: "酒店/餐饮/娱乐"
    },
    {
        name: "金融/保险/法律",
        val: "金融/保险/法律"
    },
    {
        name: "检测/认证",
        val: "检测/认证"
    },
    {
        name: "其他",
        val: "其他"
    },
]

//人员规模
export const staffSize = [{
        name: "100人以下",
        val: "100人以下"
    },
    {
        name: "100~199人",
        val: "100~199人"
    },
    {
        name: "200~499人",
        val: "200~499人"
    },
    {
        name: "500~1000人",
        val: "500~1000人"
    },
    {
        name: "1000人以上",
        val: "1000人以上"
    },
    {
        name: "5000人以上",
        val: "5000人以上"
    },
]

//区内 片区
export const district = [{
        name: '东区',
        val: '东区'
    },
    {
        name: '西区',
        val: '西区'
    },
    {
        name: '科学城',
        val: '科学城'
    },
    {
        name: '永和',
        val: '永和'
    },
    {
        name: '知识城',
        val: '知识城'
    },
    {
        name: '老黄埔',
        val: '老黄埔'
    },
    {
        name: '区内其他',
        val: '区内其他'
    },

]