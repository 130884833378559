<!-- 企业资料详情 -->
<template>
  <div class="Register_info_edit">
    <header>
      <Button size="small" type="warning" @click="$router.back()">
        <Icon type="ios-arrow-back" />
        返回
      </Button>
      <Divider type="vertical" />
      <p>{{this.$route.meta.title}}</p>
    </header>

    <section>
      <Spin size="large" fix v-if="loading"></Spin>

      <Form ref="formValidate" :rules="ruleValidate" :model="formItem" :label-width="150">

        <FormItem label="企业名称" prop="name">
          <Input v-model="formItem.name" placeholder="提交审核通过后不可修改" :readonly="formItem.status == 3" style="width: 400px"></Input>
        </FormItem>

        <!-- <FormItem label="企业简称" prop="shortName">
          <Input v-model="formItem.shortName" placeholder="提交审核通过后不可修改" readonly style="width: 400px" :readonly="formItem.status == 3"></Input>
        </FormItem> -->

        <FormItem label="社会信用代码" prop="creditCode">
          <Input v-model="formItem.creditCode" placeholder="提交审核通过后不可修改" :readonly="formItem.status == 3" style="width: 400px"></Input>
        </FormItem>

        <FormItem label="营业执照" prop="file">
          <img v-if="formItem.licenseImage" :src="img_url(formItem.licenseImage)" alt="" style="width:20%;height:20%;cursor: pointer;" @click="()=>{this.modal1 = true;this.Modal_img = img_url(formItem.licenseImage)}">
          <div style="display:flex">
            <Upload action="" :before-upload="before_licenseImage">
              <Button icon="ios-cloud-upload-outline" :disabled="formItem.status == 3">上传营业执照</Button>
            </Upload>
            <span>上传营业执照或加盖公章的复印件</span>
          </div>
        </FormItem>

        <div style="color:#FF8300;font-size: 14px;margin-top: 24px;margin-bottom: 45px;line-height: 18px;text-align: center;">以上信息以及营业执照提交审核通过后不可更改</div>

        <FormItem label="公司LOGO">
          <img v-if="formItem.logo" :src="formItem.logo" alt="" style="width:20%;height:20%;cursor: pointer;" @click="()=>{this.modal1 = true;this.Modal_img = formItem.logo}">
          <Upload action="" :before-upload="handleBeforeUpload">
            <Button icon="ios-cloud-upload-outline">上传公司LOGO</Button>
          </Upload>

        </FormItem>

        <FormItem label="所属行业" prop="trade">
          <Select v-model="formItem.trade" style="width:400px">
            <Option v-for="item in trade" :value="item.val" :key="item.val">{{ item.name }}</Option>
          </Select>
        </FormItem>

        <!-- <FormItem label="人员规模" prop="staffSize">
          <Input v-model="formItem.staffSize" style="width: 400px;"></Input>
        </FormItem> -->

        <FormItem label="人员规模" prop="staffSize">
          <Select v-model="formItem.staffSize" style="width:400px">
            <Option v-for="item in staffSize" :value="item.val" :key="item.val">{{ item.name }}</Option>
          </Select>
        </FormItem>

        <!-- <strong style="margin-left:70px">区内企业选择</strong>

        <div style="background:#FFF4EA;padding:10px 0;margin-bottom:15px">
          <FormItem label="所属片区" prop="street" :rules="formItem.street[1] == '黄埔区'?ruleValidate.street:[{required:false}]">
            <RadioGroup v-model="formItem.street[2]" @on-change='district_change'>
              <Radio style="width:20%" v-for="(item,index) in district" :key="index" :label="item.name"></Radio>
            </RadioGroup>
          </FormItem>

          <FormItem label="所属街道" prop="belongingStreet" :rules="formItem.street[1] != '黄埔区'?[{required:false}]:ruleValidate.belongingStreet">
            <RadioGroup v-model="formItem.belongingStreet">
              <Radio v-for="(item,index) in cityList" :key="index" :label="item"></Radio>
            </RadioGroup>
          </FormItem>
        </div>

        <strong style="margin-left:70px">区外企业选择</strong>

        <div style="background:#FFF4EA;">
          <FormItem label="所属片区" style="padding:20px 0" prop="street" :rules="formItem.street[1] != '黄埔区'?ruleValidate.street:[{required:false}]">
            <Cascader :data="workArea_data" trigger="hover" v-model="formItem.new_street" style="width: 200px;" :clearable="false" @on-change='new_street_change'></Cascader>
          </FormItem>
        </div>

        <p style="margin-bottom:20px;font-size:14px;color:rgb(255, 131, 0);text-align:center">请企业按照自身情况在以上两个选项中选择其中一个进行填写</p> -->

        <FormItem label="所在区域" prop="street">
          <Cascader :data="workArea_data" v-model="formItem.street" trigger="hover" :clearable="false" style="width: 400px;"></Cascader>
        </FormItem>

        <FormItem label="公司地址" prop="address">
          <div class="address" style="width: 400px;">
            <Input v-model="formItem.address" style="width:90%" placeholder="请点击右边图标选择工作地点"></Input>
            <div style="width:10%;cursor: pointer;">
              <img @click="open_Modal" src="@/assets/images/B_user/address.png" alt="">
            </div>

          </div>

        </FormItem>

        <FormItem label="公司简介" prop="description">
          <Input v-model="formItem.description" type="textarea" :autosize="{minRows: 5,maxRows: 5}" show-word-limit maxlength="100" style="width: 400px;"></Input>
        </FormItem>

        <FormItem label="公司网站网址">
          <Input v-model="formItem.webSite" style="width: 400px;"></Input>
        </FormItem>

        <FormItem label="公司介绍图片" class="introduce_images">

          <div class="demo-upload-list" v-for="(item,index) in formItem.images">

            <img :src="img_url(item)">
            <div class="demo-upload-list-cover">
              <Icon type="ios-eye-outline" @click.native="referral_img(item)"></Icon>
              <Icon type="ios-trash-outline" @click.native="handleRemove(index)"></Icon>
            </div>

          </div>

          <Upload ref="upload" action='' type="drag" style="display: inline-block;width:58px;" :before-upload="before_images">
            <div style="width: 58px;height:58px;line-height: 58px;">
              <Icon type="ios-camera" size="20"></Icon>
            </div>
          </Upload>

        </FormItem>

        <FormItem style="text-align:center;margin-bottom:100px">
          <Button @click="$router.back()">取消</Button>
          <Button type="primary" style="margin-left:20px" @click="ok('formValidate')">保存修改</Button>
        </FormItem>
      </Form>

      <Modal v-model="modal1" title="" footer-hide>
        <img :src="Modal_img" alt="" style="width:100%;height:100%">
      </Modal>

      <Modal v-model="address_model" title="地图选点" :styles="{top: '20px'}" footer-hide>
        <iframe id="mapPage" width="100%" height="800px" frameborder=0 src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=4YYBZ-BIGCG-IMDQT-IOPQG-R77A6-KOFNR&referer=myapp">
        </iframe>
      </Modal>
    </section>

    <footer>

    </footer>

  </div>
</template>

<script>
// import $ from 'jquery'
// import Tesseract from 'tesseract.js'
import { trade, staffSize, district } from "@/libs/public"



import "./hackmap/KOFNR"
import "./hackmap/geolocation"
import { CheckSocialCreditCode } from "@/libs/Fun"
export default {
  data() {
    return {
      district,
      //地级数据
      workArea_data: [
        {
          value: '广东省',
          label: '广东省',
          children: [
            {
              value: '广州市',
              label: '广州市',
              children: [
                {
                  value: '荔湾区',
                  label: '荔湾区',
                },
                {
                  value: '越秀区',
                  label: '越秀区',
                },
                {
                  value: '海珠区',
                  label: '海珠区',
                },
                {
                  value: '天河区',
                  label: '天河区',
                },
                {
                  value: '白云区',
                  label: '白云区',
                },
                {
                  value: '黄埔区',
                  label: '黄埔区',
                },
                {
                  value: '番禺区',
                  label: '番禺区',
                },
                {
                  value: '花都区',
                  label: '花都区',
                },
                {
                  value: '南沙区',
                  label: '南沙区',
                },
                {
                  value: '从化区',
                  label: '从化区',
                },
                {
                  value: '增城区',
                  label: '增城区',
                },
              ]
            },

          ],
        }],


      trade: trade,
      staffSize,
      Modal_img: "",
      loading: false,
      modal1: false,

      formItem: {
        name: "",
        // shortName: "",
        creditCode: "",
        licenseImage: '',
        trade: '',
        belongingStreet: '',
        staffSize: "",
        street: [],
        new_street: [],
        logo: '',
        address: '',
        description: '',
        images: [],
        gps: {
          x: 1,
          y: 1
        },
        webSite: '',
        telphone: '',

      },
      ruleValidate: {
        name: [
          { required: true, message: '', trigger: 'blur' }
        ],
        // shortName: [
        //   { required: true, message: '', trigger: 'blur' }
        // ],
        // status: [
        //   { required: true, message: '', trigger: 'blur' }
        // ],
        creditCode: [
          { required: true, message: '请填写社会信用代码', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请选择公司地址' }
        ],
        file: [{
          required: true,
          message: '请上传附件',
          trigger: 'change',
          validator: (rule, value, callback) => {
            if (!this.formItem.licenseImage) {
              return callback(new Error('请上传附件！'))
            } else {
              callback()
            }
          },
        }],
        trade: [
          { required: true, message: '请填写所属行业', trigger: 'change' }
        ],
        staffSize: [
          { required: true, message: '请填写人员规模', trigger: 'change' }
        ],
        street: [
          { required: true, message: '请选择所属片区', }
        ],
        belongingStreet: [
          { required: true, message: '请填写所属街道', trigger: 'change' }
        ],
        description: [
          { required: true, message: '请填写公司简介', }
        ],
      },
      //发送后端logo
      send_logo: '',
      //Modal
      address_model: false,
    }
  },
  // computed: {
  //   cityList() {
  //     let val = this.formItem.street[2]
  //     let arr = val == '东区' ? ['云埔街道', '南岗街道'] : val == '西区' ? ['穗东街道', '夏港街道'] : val == '科学城' ? ['联和街道', '萝岗街道', '长岭街道'] : val == '永和' ? ['永和街道', '永宁街道'] : val == '知识城' ? ['龙湖街道', '新龙街道', '九佛街道'] : val == '老黄埔' ? ['文冲街道', '大沙街道', '红山街道'] : val == '区内其他' ? ['鱼珠街道', '长洲街道', '其他街道'] : []
  //     return arr
  //   },

  // },
  methods: {


    // district_change(val) {
    //   this.formItem.street = []
    //   this.formItem.new_street = []
    //   this.formItem.street[0] = '广州市'
    //   this.formItem.street[1] = '黄埔区'
    //   this.formItem.street[2] = val
    //   this.formItem.belongingStreet = ''
    //   console.log(this.formItem.street);

    // },
    // new_street_change(val1) {
    //   this.formItem.street = val1
    // },
    //查看公司介绍图片
    referral_img(item) {
      this.modal1 = true;
      this.Modal_img = this.img_url(item)
    },
    //移除公司介绍图片
    handleRemove(index) {
      this.formItem.images.splice(index, 1)
    },

    // 上传营业执照
    before_licenseImage(file) {
      //判断是否图片格式文件
      console.log(file);
      if (file.type == 'image/png' || file.type == 'image/jpeg') {
        this.upLoad({
          // 云存储的路径
          cloudPath: 'weblicenseImage' + "-" + new Date().getTime() + "-" + Math.floor(Math.random() * 1000) + file.name.match(/\.[^.]+?$/)[0],
          // 需要上传的文件，File 类型
          filePath: file
        }).then((res) => {
          console.log(res);
          if (this.formItem.licenseImage) {
            this.formItem.licenseImage = res.fileID
          } else {
            this.$set(this.formItem, 'licenseImage', res.fileID)
          }
        })
      } else {
        this.$Notice.warning({
          title: "格式错误",
          desc: `${file.name}  格式错误,</br>只能上传png或jpeg格式图片`,
          druation: 0,
        });
        return false;
      }
    },

    //公司logo上传前
    handleBeforeUpload(file) {
      //判断是否图片格式文件
      if (file.type == 'image/png' || file.type == 'image/jpeg') {
        this.upLoad({
          // 云存储的路径
          cloudPath: 'weblogoImage' + "-" + new Date().getTime() + "-" + Math.floor(Math.random() * 1000) + file.name.match(/\.[^.]+?$/)[0],
          // 需要上传的文件，File 类型
          filePath: file
        }).then((res) => {
          console.log(res);
          this.send_logo = res.fileID
          if (this.formItem.logo) {
            this.formItem.logo = res.download_url
          } else {
            this.$set(this.formItem, 'logo', res.download_url)
          }
        })
      } else {
        this.$Notice.warning({
          title: "格式错误",
          desc: `${file.name}  格式错误,</br>只能上传png或jpeg格式图片`,
          druation: 0,
        });
        return false;
      }
    },

    //公司介绍图片上传前
    before_images(file) {
      console.log();
      if (!this.formItem.images) {
        this.$set(this.formItem, "images", [])
      }
      if (this.formItem.images.length < 3) {


        if (file.type == 'image/png' || file.type == 'image/jpeg') {
          console.log(file);
          this.upLoad({
            cloudPath: 'webImages' + "-" + new Date().getTime() + "-" + Math.floor(Math.random() * 1000) + file.name.match(/\.[^.]+?$/)[0],
            filePath: file
          }).then((res) => {
            console.log(res);
            if (this.formItem.images) {
              this.formItem.images.push(res.fileID)
            } else {
              this.$set(this.formItem, "images", [res.fileID])
            }


          })
        }
      } else {
        this.$Modal.warning({
          title: "介绍图片最多上传3张",
        });
      }
    },

    //地图选点返回事件
    onMessage(event) {
      let loc = event.data
      if (loc && loc.module == 'locationPicker') {
        //防止其他应用也会向该页面post信息，需判断module是否为
        //选点完毕关闭Modal
        this.address_model = false
        this.formItem.address = loc.poiaddress + loc.poiname
        this.formItem.gps.x = loc.latlng.lng
        this.formItem.gps.y = loc.latlng.lat
      }
    },
    //打开地图选点
    open_Modal() {
      this.address_model = true
    },


    // 修改职位详情
    ok(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let data = {
            _id: this.$route.query.id,
            name: this.formItem.name, //企业名称
            // shortName: this.formItem.shortName, //企业简称
            creditCode: this.formItem.creditCode,  //社会信用代码
            licenseImage: this.formItem.licenseImage,  //营业执照图片
            logo: this.formItem.logo, // 公司LOGO
            trade: this.formItem.trade,   //所属行业
            staffSize: this.formItem.staffSize,   //人员规模
            street: this.formItem.street,  //所在片区
            belongingStreet: this.formItem.belongingStreet,  //所在街道
            address: this.formItem.address,  //公司地址
            gps: this.formItem.gps,       //公司地址gps
            description: this.formItem.description, //公司简介
            webSite: this.formItem.webSite, //公司网站网址
            telphone: this.formItem.telphone,
            images: this.formItem.images,

          }


          if (this.formItem.gps.x == 1) {
            this.$Modal.confirm({
              title: '请先地图定位公司地址',
            })
            return false
          }
          if (!/^[\dA-Z]{18}$/.test(this.formItem.creditCode)) {
            this.$Modal.warning({
              title: "统一社会信用代码格式错误"
            })
            return false
          }

          this.app('enterpriseOperators', 'updateEnterprise', data).then(res => {

            if (res.code == 200) {
              this.$Message.success("修改成功")
              this.$router.back()
              //储存公司地址
              localStorage.setItem("firm_address", this.formItem.address)
              //储存公司地址gps
              localStorage.setItem("firm_gps", JSON.stringify(this.formItem.gps))
              //储存公司所属片区
              localStorage.setItem("firm_street", JSON.stringify(this.formItem.street))
              //储存公司所属街道
              localStorage.setItem("firm_belongingStreet", JSON.stringify(this.formItem.belongingStreet))

              this.$emit("update")
            }
            this.$Message.warning(res.message)
          })
        } else {
        }
      })


    },

    //封装获取详情
    get_info() {
      this.loading = true
      this.app('enterpriseOperators', 'getEnterprise', {
        enterpriseId: this.$route.query.id ? this.$route.query.id : this.$route.params.id
      }).then(res => {
        console.log(res);
        if (res.code == 200) {

          if (res.result.logo) {
            res.result.logo = res.result.logo.replace('cloud://test-1po4v.7465-test-1po4v-1303180979', 'https://7465-test-1po4v-1303180979.tcb.qcloud.la');

          }

          if (!res.result.gps) {
            this.$set(res.result, "gps", { x: 1, y: 1 })
          }



          res.result.date = this.dayjs(res.result.date).format(
            "YYYY/MM/DD"
          );

          this.formItem = res.result
          this.formItem.new_street = this.formItem.street
        } else {
          this.$Modal.warning({
            title: res.message,
          });
        }

        this.loading = false

      })
    },



  },
  mounted() {

    console.log(1);
    //接收来自其他页面的消息并判断发送者
    window.addEventListener("message", this.onMessage);
  },
  created() {


    var change_company = this.$route.query.change_company
    if (change_company == 1) {

    } else {
      this.$Modal.warning({
        title: '为了您的方便，带红点项为必填项，请先填完再提交信息！',
      });
    }
    this.get_info()
  }
}
</script>

<style lang="less" scoped>
@import "./firm_data_detail.less";
</style>